import Dinero from 'dinero.js'
import {
  isSameDay,
  format,
  formatRelative,
  isWithinInterval,
  subDays,
} from 'date-fns'
import queryString from 'query-string'
import randomBytes from 'randombytes'
import KSUID from 'ksuid'
import { LineItemExtended, LineItemGroup, VariantInputWithAudit } from '@/types'
import Countries from '@/assets/countries.json'
import {
  CurrencyEnum,
  Price,
  VariantInput,
  VariantTypeEnum,
  Maybe,
  FulfillmentItemInput,
  FulfillmentStatusEnum,
  Fulfillment,
  Order,
  LineItem,
  Refund,
  Tax,
} from './../../../shared/types/types'
import { MAX_OPTIONS } from '../constants/'
import { IntervalEnum } from '../lib/reports/types'
import { deleteVariant } from './product'
import { getDecimalPlaces } from './currencies'

interface BuildQueryStringArguments {
  searchText?: string
  algoliaFilterString?: string
  page?: number
}

export const formatPrice = (price: Price): string => {
  // console.log('🚀 ~ file: utils.ts:40 ~ formatPrice ~ price', price)
  if (price && !Object.keys(price).includes('amount')) {
    console.error('Price object needs to have the amount property', price)
    throw new Error('Price object needs to have the amount property')
  } else if (price && !Object.keys(price).includes('currency')) {
    console.error('Price object needs to have the currency property', price)
    throw new Error('Price object needs to have the currency property')
  } else if (price && price.amount && Number.isNaN(price.amount)) {
    console.error('Amount should be an integer value', price)
    throw new Error('Amount should be an integer value')
  } else if (!price.currency) {
    console.error('Currency is required', price)
    throw new Error('Currency is required')
  }
  // Need to support only integers, since price values are stored in cents
  const amount = parseInt((price?.amount || 0).toString(), 10)

  const { currency } = price
  const precision = getDecimalPlaces(currency)

  const format = precision === 0 ? '$0,0' : `$0,0.${'0'.repeat(precision)}`
  return Dinero({
    amount,
    currency: currency as Dinero.Currency,
    precision,
  }).toFormat(format)
}

export const currencyToSymbol = (currencyCode: string): string => {
  const returnValue =
    Dinero({ amount: 0, currency: currencyCode as Dinero.Currency }).toFormat(
      '$'
    ) || ''
  return returnValue
}

/**
 * Return one of three formatted dates depending on how far back the date is
 *
 * @param date Date
 */
export const formatDate = (dateToFormat: Date): string => {
  const date = new Date(dateToFormat)

  const oneWeekAgo = subDays(new Date(), 7)

  const isDateToday = isSameDay(date, new Date())
  const isDateWithinTheLastWeek = isWithinInterval(date, {
    start: oneWeekAgo,
    end: new Date(),
  })

  // If date is today, just return the time
  if (isDateToday) {
    return format(date, 'h:mm a')
  }

  // If date is within the last week, return relative "<day> at <time>"
  let relativeString = formatRelative(date, new Date()) // last Tuesday at 3:30 pm

  if (isDateWithinTheLastWeek && relativeString.includes('at')) {
    relativeString = relativeString.toLowerCase() // last tuesday at 3:30 pm
    relativeString = relativeString.replace('last ', '') // tuesday at 3:30 pm

    relativeString =
      relativeString.charAt(0).toUpperCase() + relativeString.substr(1) // Tuesday at 3:30 pm

    return relativeString
  }

  // If date is beyond 7 days ago, return "<shortMonth> <date> at <time>"
  return `${format(date, 'LLL d, yyyy')} at ${format(date, 'K:mm a')}`
}

export const formatDateLabel = (
  dates: Date[],
  date: Date,
  interval: IntervalEnum
): string => {
  const dateToYearMap: Record<number, boolean> = {}
  const firstDatesOfMonth = dates.filter((d) => {
    const fullYear = d.getFullYear()
    if (dateToYearMap[fullYear]) {
      return false
    } else {
      dateToYearMap[fullYear] = true
      return true
    }
  })

  const includesDate = (d: Date) =>
    firstDatesOfMonth.map((d) => d.getTime()).includes(d.getTime())

  switch (interval) {
    case IntervalEnum.Day:
      if (includesDate(date)) {
        return format(new Date(date), 'dd MMM yyyy')
      } else {
        return format(new Date(date), 'dd MMM')
      }
    case IntervalEnum.Week:
      if (includesDate(date)) {
        return format(new Date(date), 'dd MMM yyyy')
      } else {
        return format(new Date(date), 'dd MMM')
      }
    case IntervalEnum.Month:
      if (includesDate(date)) {
        return format(new Date(date), 'MMM yyyy')
      } else {
        return format(new Date(date), 'MMM')
      }
    case IntervalEnum.Year:
      return format(new Date(date), 'yyyy')
    default:
      return format(new Date(date), 'MM/dd/yyyy')
  }
}

// Get country name from country code
export const getCountryName = (countryCode: string): string => {
  const country = Countries.data.find((c) => c.code === countryCode)
  return country ? country.name : ''
}

/**
 * Return different compinations for the given record
 * @param options - <string,any>
 */
export const getCombinations = (
  options: Record<string, any>
): Record<string, any> => {
  return (function recurse(keys) {
    if (!keys.length) return [{}]
    const result = recurse(keys.slice(1))
    return options[keys[0]].reduce(
      (acc: Record<string, any>, value: Record<string, any>) =>
        acc.concat(
          result.map((item: Record<string, any>) =>
            Object.assign({}, item, { [keys[0]]: value })
          )
        ),
      []
    )
  })(Object.keys(options))
}

/**
 * Return default variant with product title as option value
 * @param options - <string,any>
 */
export const getDefaultVariant = (title: string): VariantInput => {
  const variant: VariantInput = {
    title: title,
    option1: title,
    chargeTax: false,
    disableAutomaticDiscount: false,
    isOversellable: false,
    price: {
      amount: 0,
      currency: CurrencyEnum.Usd,
    },
    trackQuantity: false,
    variantType: VariantTypeEnum.Physical,
    quantity: 0,
    index: 0,
  }
  return variant
}

/**
 * Return title for the variant using the option values
 * @param option1 - string
 * @param option2 - string
 * @param option3 - string
 */
export const generateVariantTitle = (
  option1: string,
  option2: string,
  option3: string
): string => {
  return (
    (option1 ? option1 : '') +
    (option2 ? (option1 ? ' / ' + option2 : option2) : '') +
    (option3
      ? option2
        ? ' / ' + option3
        : option1
        ? ' / ' + option3
        : option3
      : '')
  )
}

/**
 * set lineItems to fulfilled
 *
 * @param order order lineitems and order fulfillments
 */
export const setFulfilledLineItem = (order: Maybe<Order> = {}): Order[] => {
  let fulfilledLineitems: any[] = []
  if (order?.fulfillments) {
    order?.fulfillments.forEach((fulfillment: any) => {
      fulfillment.lineItems.forEach((lineItem: any) => {
        const previouslyFulfilledLineItem = fulfilledLineitems.findIndex(
          (item) => item.id === lineItem.id
        )
        if (previouslyFulfilledLineItem !== -1) {
          fulfilledLineitems[previouslyFulfilledLineItem] = {
            ...fulfilledLineitems[previouslyFulfilledLineItem],
            quantity:
              fulfilledLineitems[previouslyFulfilledLineItem].quantity +
              lineItem.quantity,
          }
        } else {
          fulfilledLineitems.push(lineItem)
        }
      })
    })
    fulfilledLineitems = fulfilledLineitems.map((fulfilledLineItem) => {
      return {
        ...(order?.lineItems as LineItemExtended[]).find(
          (lineItem) => lineItem.lineItemId === fulfilledLineItem.id
        ),
        quantity: fulfilledLineItem.quantity,
        selectedQuantity: 0,
      }
    })
  }
  return fulfilledLineitems
}

/**
 * Changes selected quantity of lineItems array in order object, updates selected properties
 *
 * @param item data transfered by LineItemExtended when user changes quantity of lineItems.
 */

export const changeSelectedLineItems = (
  item: LineItemExtended,
  lineItems: LineItemExtended[]
) => {
  const selected = {
    quantity: 0,
    amount: 0,
    taxes: [] as Tax[],
  }
  lineItems.forEach((lineItem) => {
    if (lineItem.lineItemId === item.lineItemId) {
      lineItem.selectedQuantity = item?.selectedQuantity || 0
    }
    selected.quantity += +(lineItem?.selectedQuantity || 0)
    selected.amount += +(
      (lineItem.selectedQuantity || 0) * (lineItem?.originalPrice?.amount || 0)
    )
    ;(lineItem.taxes as Tax[])?.forEach((tax) => {
      const lineItemTax = selected.taxes.findIndex(
        (selectedTax) => selectedTax.taxId === tax?.taxId
      )
      if (lineItemTax !== -1) {
        ;(selected.taxes[lineItemTax].taxPrice as Price).amount =
          ((selected.taxes[lineItemTax].taxPrice as Price).amount || 0) +
          (tax?.taxPrice?.amount || 0) * (lineItem.selectedQuantity || 0)
      } else {
        selected.taxes.push({
          ...tax,
          taxPrice: {
            currency: tax.taxPrice?.currency,
            amount:
              (tax?.taxPrice?.amount || 0) * (lineItem.selectedQuantity || 0),
          },
        })
      }
    })
  })
  return selected
}

/**
 * Group lineItems to fulfilled/unfulfilled
 *
 * @param fulfillmentType FulfillmentStatusEnum
 * @param variantType VariantTypeEnum
 * @param lineItems Order lineitems
 * @param fulfillments order.fulfillments array
 */
export const groupedFulfillmentLineItems = (
  fulfillmentType: FulfillmentStatusEnum,
  variantType?: VariantTypeEnum,
  lineItems: Maybe<LineItemExtended>[] = [],
  fulfillments: Maybe<Array<Maybe<Fulfillment>>> = []
): LineItemGroup[] => {
  let groupedLineItems = [] as LineItemGroup[]
  switch (fulfillmentType) {
    case FulfillmentStatusEnum.Fulfilled: {
      for (let i = 0; i < ((fulfillments as Fulfillment[]) || []).length; i++) {
        const LineItemGroup = {
          fulfillmentName: `F${i + 1}`,
          fulfillmentId: (fulfillments as Fulfillment[])[i].id,
          lineItems: [] as LineItemExtended[],
          tracker: (fulfillments as Fulfillment[])[i].tracker,
        }
        // Loop throught fulfillment lineItems and find matching lineItem in lineItems array
        ;(((fulfillments as Fulfillment[])[i] as Fulfillment)
          ?.lineItems as FulfillmentItemInput[])?.forEach(
          (lineItem: FulfillmentItemInput) => {
            const fulfillmentLineItem = (lineItems as LineItemExtended[]).filter(
              (item: LineItemExtended) =>
                item.lineItemId === lineItem.id &&
                (variantType ? item.variantType === variantType : true)
            )
            if (fulfillmentLineItem.length) {
              LineItemGroup.lineItems.push({
                ...fulfillmentLineItem[0],
                quantity: lineItem.quantity,
                totalPrice: {
                  ...fulfillmentLineItem[0]?.totalPrice,
                  amount:
                    lineItem.quantity *
                    (fulfillmentLineItem[0].originalPrice?.amount || 0),
                },
                // TODO: This will change once the tax and discounts are implemented
                finalPrice: {
                  ...fulfillmentLineItem[0]?.totalPrice,
                  amount:
                    lineItem.quantity *
                    (fulfillmentLineItem[0].originalPrice?.amount || 0),
                },
              })
            }
          }
        )
        if (LineItemGroup.lineItems.length) {
          groupedLineItems.push(LineItemGroup as LineItemGroup)
        }
      }
      break
    }
    case FulfillmentStatusEnum.Unfulfilled: {
      groupedLineItems.push({ lineItems: [] })
      const fulfilledLineitems: any[] = []
      if (fulfillments) {
        fulfillments.forEach((fulfillment: any) => {
          fulfillment.lineItems.forEach((lineItem: any) => {
            const previouslyFulfilledLineItem = fulfilledLineitems.findIndex(
              (item) => item.id === lineItem.id
            )
            if (previouslyFulfilledLineItem !== -1) {
              fulfilledLineitems[previouslyFulfilledLineItem] = {
                ...fulfilledLineitems[previouslyFulfilledLineItem],
                quantity:
                  fulfilledLineitems[previouslyFulfilledLineItem].quantity +
                  lineItem.quantity,
              }
            } else {
              fulfilledLineitems.push(lineItem)
            }
          })
        })
      }
      ;(lineItems as LineItemExtended[]).forEach((item) => {
        if (variantType ? item?.variantType === variantType : true) {
          const unFulfilledQuantity =
            (item?.quantity || 0) -
            (fulfilledLineitems.find(
              (fulfilledItem) => fulfilledItem.id === item.lineItemId
            )?.quantity || 0)
          if (unFulfilledQuantity) {
            item = {
              ...item,
              quantity: unFulfilledQuantity,
              totalPrice: {
                ...item.totalPrice,
                amount: (item.originalPrice?.amount || 0) * unFulfilledQuantity,
              },
              // TODO: This will change once the tax and discounts are implemented
              finalPrice: {
                ...item.totalPrice,
                amount: (item.originalPrice?.amount || 0) * unFulfilledQuantity,
              },
            }
            groupedLineItems[0].lineItems.push(item)
          }
        }
      })
      if (!groupedLineItems[0].lineItems.length) {
        groupedLineItems = []
      }
      break
    }
  }
  return groupedLineItems
}

/**
 * filter refunded lineItems
 *
 * @param lineItems LineItem[]
 * @param refunds Refund[]
 */
export const filterRefundedLineItems = (
  lineItems: LineItem[],
  refunds: Refund[]
): any => {
  const refundedLineItems = [] as LineItem[]
  for (let i = 0; i < refunds.length; i++) {
    refunds[i].lineItems?.forEach((refundedLineItem) => {
      const lineItemAt = lineItems.findIndex(
        (lineItem) => lineItem.lineItemId === refundedLineItem?.id
      )
      const refundedLineItemAt = refundedLineItems.findIndex(
        (refundedLineItemAdded) =>
          refundedLineItemAdded.lineItemId === refundedLineItem?.id
      )
      if (refundedLineItemAt === -1) {
        refundedLineItems.push({
          ...lineItems[lineItemAt],
          quantity: refundedLineItem?.quantity || 0,
          totalPrice: {
            ...lineItems[lineItemAt].totalPrice,
            amount:
              ((lineItems[lineItemAt].originalPrice as Price).amount || 0) *
              (refundedLineItem?.quantity || 0),
          },
        })
      } else {
        refundedLineItems[refundedLineItemAt].quantity =
          (refundedLineItems[refundedLineItemAt].quantity || 0) +
          (refundedLineItem?.quantity || 0)
        ;(refundedLineItems[refundedLineItemAt].totalPrice as Price).amount =
          (refundedLineItems[refundedLineItemAt].originalPrice?.amount || 0) *
          (refundedLineItems[refundedLineItemAt].quantity || 0)
      }
      lineItems[lineItemAt]
      const lineItem = lineItems[lineItemAt]
      if (!lineItem) {
        return
      }
      if ((lineItem.quantity || 0) - (refundedLineItem?.quantity || 0)) {
        lineItem.quantity =
          (lineItem.quantity || 0) - (refundedLineItem?.quantity || 0)
        ;(lineItem.totalPrice as Price).amount =
          (lineItem.originalPrice?.amount || 0) * (lineItem.quantity || 0)
      } else {
        lineItems.splice(lineItemAt, 1)
      }
    })
  }
  return { lineItems, refundedLineItems }
}

/**
 * Generates temporary variants based on the given option labels generated
 *
 * @param generatedOptions - generated combinations of variants
 * @param variantsForPreview - variants that are shown
 * @param inEditMode - edit mode or create mode
 * @param productId - product id that is processed
 * @param selectedOptionLabels - option labels that are selected
 * @param isEditVariant - edit variant or not
 */
export const getTempVariantsForPreview = (
  generatedOptions: any,
  variantsForPreview: VariantInputWithAudit[],
  inEditMode: boolean,
  productId: string,
  selectedOptionLabels: string[],
  isEditVariant = false
): VariantInputWithAudit[] => {
  let tempVariantsForPreview: VariantInputWithAudit[] = []
  const variantsToDelete: VariantInput[] = []
  variantsForPreview.forEach(
    async (variantInputWithAudit: VariantInputWithAudit) => {
      const { variant } = variantInputWithAudit
      let isVariantPresent = false
      for (let i = 1; i <= generatedOptions.length; i++) {
        let optionPresentInVariantCount = 0
        let counter = 1
        const generatedOption = generatedOptions[i - 1]
        const { option1, option2, option3 } = variant
        // if an entire option is removed then the index of the option after that will reduce
        // if option1, option2, option3 are present, if option 2 is removed then option3 will become option 2
        // hence find if the option value is present in any of the options in that particular variant or else remove it
        while (counter <= MAX_OPTIONS) {
          if (
            (option1 &&
              generatedOption[`option${counter}`] &&
              option1 === generatedOption[`option${counter}`]) ||
            (option2 &&
              generatedOption[`option${counter}`] &&
              option2 === generatedOption[`option${counter}`]) ||
            (option3 &&
              generatedOption[`option${counter}`] &&
              option3 === generatedOption[`option${counter}`])
          ) {
            optionPresentInVariantCount++
          }
          counter++
        }
        if (isEditVariant) {
          optionPresentInVariantCount++
        }
        // if option matches in variant is equal to the available option labels, deem it as valid
        if (optionPresentInVariantCount === selectedOptionLabels.length) {
          // remove the generated option to optimize the number of loop execution
          tempVariantsForPreview.push({
            ...variantInputWithAudit,
            variant: {
              ...variantInputWithAudit.variant,
              option1: generatedOptions[i - 1].option1,
              option2: generatedOptions[i - 1].option2,
              option3: generatedOptions[i - 1].option3,
              title: generateVariantTitle(
                generatedOptions[i - 1].option1,
                generatedOptions[i - 1].option2,
                generatedOptions[i - 1].option3
              ),
              index: i - 1,
            },
          })
          isVariantPresent = true
          if (!inEditMode) generatedOptions.slice(i - 1, 1)
          break
        }
      }
      if (!isVariantPresent && inEditMode) {
        variantsToDelete.push(variant)
      }
    }
  )
  // delete the variants that are removed
  Promise.all(
    variantsToDelete.map(async (variant: VariantInput) => {
      await deleteVariant(productId, variant?.variantId || '')
    })
  )
  // sort variants based on the index value
  tempVariantsForPreview = tempVariantsForPreview.sort(
    (a: VariantInputWithAudit, b: VariantInputWithAudit) =>
      a.variant.index > b.variant.index
        ? 1
        : b.variant.index > a.variant.index
        ? -1
        : 0
  )

  return tempVariantsForPreview
}

export const generateKSUID = (timestamp = new Date()): string => {
  return KSUID.fromParts(timestamp.getTime(), randomBytes(16)).string
}

export const buildQueryString = ({
  searchText,
  page,
}: BuildQueryStringArguments): string => {
  const queryParams: Record<string, any> = {
    ...(searchText && {
      query: searchText,
    }),
    ...(page && {
      page,
    }),
  }

  return queryString.stringify(queryParams)
}

export const getTaxValue = (tax: Tax): string => {
  return tax.flatRate ? formatPrice(tax.flatRate) : `${tax.percentage}%`
}

export const capitalizeFirstLetter = (text: string): string => {
  if (!text) {
    return ''
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const addCommas = (input: string | number): string => {
  let nStr = input.toString()
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
