export const MAX_OPTIONS = 3
export const OPTION_LABELS = ['Size', 'Color', 'Material', 'Style', 'Title']
export const DEFAULT_OPTION_LABEL = 'Title'
export const MAX_ALLOWED_FILE_SIZE_FOR_PRODUCT_IMAGE = 10
export const TRACKING_NUMBER_PLACEHOLDER = '{trackingNumber}'
export const DEFAULT_VARIANT_TITLE = 'Default Title'
export enum AssetPrefixKey {
  Inventory = 'inventory',
  FulfillmentGroup = 'fulfillment-group',
}
