export type FilterMappingsOperators =
  | 'is'
  | 'is not'
  | 'greater than'
  | 'less than'
  | 'greater or equal'
  | 'less or equal'

export type FilterMappingsInputs =
  | 'checkbox'
  | 'select'
  | 'text'
  | 'tags'
  | 'price'

export interface FilterMapping {
  name: string
  operators: FilterMappingsOperators[]
  input: FilterMappingsInputs
  options?: {
    label: string
    value: string
  }[]
  vOn?: {
    [key: string]: (value: any) => any | Promise<any>
  }
  vBind?: {
    [key: string]: any
  }
}

export interface FilterMappings {
  [key: string]: FilterMapping
}

export interface Filter {
  filter: string
  operator: FilterMappingsOperators
  values: any[]
}

export interface AndFilterGroup {
  orGroups: Filter[]
}

export interface DatePickerData {
  startDate: Date
  endDate: Date
  timezone: string
}

export enum IntervalEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}
